import React from 'react'
import { Container, Row, Col, Card, Media } from 'reactstrap'
import Content, { HTMLContent } from '../components/Content'
import moment from 'moment'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'

import bannerImage from '../img/contact-bg.jpg'
import Banner from '../components/Banner'
import Layout from '../components/Layout'

import DefaultImage from '../img/safestart-app-icon.png'

const NewsPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  const renderedArticles = (
    <section className="section">
      <Container>
        {posts.map(({ node: post }) => (
          <div key={post.id}>
            <div className="small text-muted text-right">
              {moment(post.frontmatter.date).format('dddd, MMMM Do YYYY')}
            </div>
            <Card body className="space-below">
              <Media>
                <Media left>
                  <Media
                    object
                    src={
                      post.frontmatter.thumbnail
                        ? post.frontmatter.thumbnail.publicURL
                        : DefaultImage
                    }
                    alt="News"
                    style={{
                      width: 64,
                      height: 64,
                      borderRadius: 6,
                      objectFit: 'cover'
                    }}
                  />
                </Media>
                <Media body style={{ marginLeft: 20 }}>
                  <Media heading>
                    <a
                      className="text-dark"
                      href={post.frontmatter.url}
                      target="_blank"
                    >
                      {post.frontmatter.title}
                    </a>
                  </Media>
                  <HTMLContent content={post.frontmatter.description} />
                </Media>
              </Media>
            </Card>
          </div>
        ))}
      </Container>
    </section>
  )

  return (
    <Layout>
      <Banner image={bannerImage} title="News" />

      <section className="padded-section bg-light">
        <Container>{renderedArticles}</Container>
      </section>
    </Layout>
  )
}

export default NewsPage

export const newsQuery = graphql`
  query NewsQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { templateKey: { eq: "news-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            url
            description
            thumbnail {
              publicURL
            }
          }
        }
      }
    }
  }
`
